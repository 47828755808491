import DocumentGenerationForm from "../../../../features/catalog/components/documentGenerationForm/DocumentGenerationForm"
import { ContentBlockIds } from "../../../../features/catalog/catalog.constants"
import { CatalogApi } from "../../../../features/catalog/catalog.api"
import usePrint from "../../../../features/sales/hooks/usePrint"

export default function ProjectReports() {
    const documentTypes = CatalogApi.useDocumentTypesQuery(ContentBlockIds.ProjectDocumentTypes).data ?? []
    const print = usePrint()
    const submit = (documentTemplate: string, documentFormat: string) => print({ documentTemplate, documentFormat })
    return <DocumentGenerationForm documentTypes={documentTypes} onSubmit={submit} />
}
