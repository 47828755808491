import { ClassAttributes, DetailedHTMLProps, ForwardedRef, forwardRef, HTMLAttributes, MouseEvent, useContext } from "react"
import { useSearchParams } from "react-router-dom"
import { MUIStyledCommonProps } from "@mui/system"
import { TreeItem2Label, useTreeItemState } from "@mui/x-tree-view"
import { Stack, Theme, Tooltip, Typography } from "@mui/material"
import QuestionMarkIcon from "@mui/icons-material/QuestionMark"
import DragHandleIcon from "@mui/icons-material/DragHandle"
import { useAppSelector } from "../../../../../../store/store"
import SalesUtils from "../../../../../../features/sales/sales.utils"
import { mergeSx } from "../../../../../../utils/mergeSx"
import { LineItemTypeIcon } from "../../../../../../features/sales/components/lineItemTypeIcon/LineItemTypeIcon"
import { LineItemReadyStateIcon } from "../../../../../../features/sales/components/lineItemReadyStateIcon/LineItemReadyStateIcon"
import Icon from "../../../../../../components/icons/Icon"
import { TreeItemStyles } from "./TreeItem.styles"
import ExpandButton from "../../../../../../components/buttons/expandButton/ExpandButton"
import { ProjectConfigurationLineItemWithParent } from "./TreeItem"
import { ProjectConfigurationTreeContext } from "../../ProjectConfiguration"
import { LineItemActionsMenuButton } from "./LineItemActionsMenuButton"
import { TreeItemIndentation } from "./TreeItemIndentation"
import { ConfigurationInterfaceDecisionsDifferencesIcon } from "../../../../../../features/sales/components/configurationInterfaceDecisionsDifferencesIcon/ConfigurationInterfaceDecisionsDifferencesIcon"
import SalesApi from "../../../../../../features/sales/sales.api"
import { getLineupLineItem, getProjectOptionsLineItem } from "../../ProjectConfiguration.utils"
import { ProjectConfigurationSearchParams } from "../../ProjectConfiguration.constants"
import { FocusLineUpVisualizationButton } from "./FocusLineUpVisualizationButton"

type TreeItemLabel2Props = MUIStyledCommonProps<Theme> &
    Pick<DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>, keyof ClassAttributes<HTMLDivElement> | keyof HTMLAttributes<HTMLDivElement>>

interface TreeItemLabelProps extends TreeItemLabel2Props {
    item: ProjectConfigurationLineItemWithParent
}

export const TreeItemLabel = forwardRef(({ item, ...props }: Readonly<TreeItemLabelProps>, ref: ForwardedRef<HTMLDivElement>) => {
    const projectConfiguration = SalesApi.endpoints.projectConfiguration.useQueryState().currentData!
    const readOnly = useAppSelector(state => state.sales.salesDocumentReadOnly)
    const expanded = useTreeItemState(item.lineItem.lineItemId).expanded
    const { expandItem, collapseItem } = useContext(ProjectConfigurationTreeContext)
    const [searchParams] = useSearchParams()

    const selectedLineItemId = searchParams.get(ProjectConfigurationSearchParams.LINE_ITEM_ID)
    const depth = (item?.lineItem?.properties.positionnumber?.match(/\./g) || []).length
    const readyState = item.lineItem.properties.readystate
    const canDrag = SalesUtils.lineItems.isDraggableLineItem(item.lineItem) && !readOnly
    const isFolder = SalesUtils.lineItems.isFolder(item.lineItem)
    const isLineup = SalesUtils.lineItems.isLineupFolder(item.lineItem)
    const name = SalesUtils.lineItems.getLineItemLabel(item.lineItem)
    const subName = SalesUtils.lineItems.getSubName(item.lineItem) !== name ? SalesUtils.lineItems.getSubName(item.lineItem) : undefined
    const projectOptions = getProjectOptionsLineItem(projectConfiguration)
    const projectOptionsDifferences = projectOptions ? SalesUtils.lineItems.getConfigurationInterfaceDecisionsDifferences(item.lineItem, projectOptions) : []
    const lineupOptions = getLineupLineItem(item)
    const lineupOptionsDifferences = lineupOptions
        ? SalesUtils.lineItems.getConfigurationInterfaceDecisionsDifferences(item.lineItem, lineupOptions)
        : undefined
    const hasConfigurationInterfaceDifferences = projectOptionsDifferences.length > 0 || (lineupOptionsDifferences?.length ?? 0) > 0

    const onToggle = (event: MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation()
        if (expanded) {
            collapseItem(item)
        } else {
            expandItem(item)
        }
    }

    return (
        <TreeItem2Label ref={ref} {...props} sx={mergeSx(TreeItemStyles.treeItemLabel, props.sx)}>
            <Stack direction="row" spacing={1} alignItems="center">
                {canDrag ? (
                    <DragHandleIcon sx={{ cursor: "grab" }} className="DragAndDropButton" />
                ) : (
                    <Icon component={QuestionMarkIcon} sx={{ visibility: "hidden" }} />
                )}
                <TreeItemIndentation depth={depth} />
                {isFolder && (
                    <ExpandButton
                        disabled={expanded && item.lineItem.properties.configurable && selectedLineItemId === item.lineItem.lineItemId}
                        expanded={expanded}
                        onToggle={onToggle}
                        sx={{ visibility: item.children.length > 0 ? "visible" : "hidden" }}
                    />
                )}
                <LineItemTypeIcon lineItem={item.lineItem} />
                <Stack direction="column">
                    <Tooltip title={name}>
                        <Typography sx={TreeItemStyles.nameLabel}>{name}</Typography>
                    </Tooltip>
                    {subName && !isFolder && (
                        <Tooltip title={subName}>
                            <Typography variant="body2" sx={TreeItemStyles.subNameLabel}>
                                {subName}
                            </Typography>
                        </Tooltip>
                    )}
                </Stack>
            </Stack>
            <Stack direction="row" spacing={1} alignItems="center" onClick={event => event.stopPropagation()}>
                {hasConfigurationInterfaceDifferences ? (
                    <ConfigurationInterfaceDecisionsDifferencesIcon
                        projectOptionsDifferences={projectOptionsDifferences}
                        lineupOptionsDifferences={lineupOptionsDifferences}
                    />
                ) : (
                    <Icon component={QuestionMarkIcon} sx={{ visibility: "hidden" }} />
                )}
                {isLineup ? <FocusLineUpVisualizationButton item={item.lineItem} /> : <Icon component={QuestionMarkIcon} sx={{ visibility: "hidden" }} />}
                {readyState ? <LineItemReadyStateIcon readyState={readyState} /> : <Icon component={QuestionMarkIcon} sx={{ visibility: "hidden" }} />}
                <LineItemActionsMenuButton item={item} />
            </Stack>
        </TreeItem2Label>
    )
})
