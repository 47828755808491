import { ContainerTO } from "@encoway/c-services-js-client"
import { CustomConfiguratorComponents } from "../../constants/CustomConfiguratorComponents"

export function filterDefaultLayoutContainer(data: ContainerTO): ContainerTO {
    if (data.children.length) {
        data.children = data.children.map(filterDefaultLayoutContainer).filter(container => container.parameters.length > 0 || container.children.length > 0)
    }
    if (!data.children.length && data.parameters.length === 1) {
        data.parameters = data.parameters.filter(parameter => parameter.viewPort !== CustomConfiguratorComponents.DefaultLayout)
    }
    return data
}
