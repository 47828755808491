import InfoIcon, { InfoIconProps } from "../icons/infoIcon/InfoIcon"
import { ReactNode } from "react"

export interface InputLabelProps extends Omit<InfoIconProps, "children"> {
    label: string
    info?: ReactNode
}

export function InputLabel({ label, info, ...infoIconProps }: Readonly<InputLabelProps>) {
    return (
        <>
            <span>{label}</span>
            {info && <InfoIcon {...infoIconProps}>{info}</InfoIcon>}
        </>
    )
}
