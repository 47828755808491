import { useCallback, useEffect } from "react"
import { generatePath, Outlet } from "react-router-dom"
import { L10n } from "@encoway/l10n"
import { Badge, Stack } from "@mui/material"
import { CheckCircle } from "@mui/icons-material"
import TranslationKeys from "../../features/translations/TranslationKeys"
import useParams from "../../router/hooks/useParams"
import Tabs from "../../components/tabs/Tabs"
import SaveButton from "../../components/buttons/saveButton/SaveButton"
import MenuButton from "../../components/buttons/menuButton/MenuButton"
import useProjectForms from "./hooks/useProjectForms/useProjectForms"
import ProjectPageStyles from "./ProjectPage.styles"
import Icon from "../../components/icons/Icon"
import { useAppSelector } from "../../store/store"
import { ShouldBlock } from "../../router/hooks/useBlocker"
import RouterPaths from "../../router/constants/RouterPaths"
import SalesApi from "../../features/sales/sales.api"
import { ProductGroupIds } from "../../features/catalog/catalog.constants"
import { SalesDocumentStatusValues } from "../../features/sales/sales.constants"
import SalesUtils from "../../features/sales/sales.utils"
import { MainContainer } from "../../components/mainContainer/MainContainer"
import { GenericFormUtils } from "../../components/genericForm/GenericForm.utils"
import Breadcrumbs, { Breadcrumb } from "../../components/breadcrumbs/Breadcrumbs"
import { useSaveConfiguration } from "./components/projectConfiguration/hooks/useSaveConfiguration"
import { ProjectPageInfoIcon } from "./ProjectPage.InfoIcon"

export default function ProjectPage() {
    const salesDocumentIsSaved = useAppSelector(state => state.sales.salesDocumentIsSaved)
    const configurationIsSaved = useAppSelector(state => state.sales.configurationIsSaved)
    const readOnly = useAppSelector(state => state.sales.salesDocumentReadOnly)

    const [openSalesDocument, openSalesDocumentResult] = SalesApi.useOpenSalesDocumentMutation()
    const salesDocument = SalesApi.useSalesDocumentQuery(undefined, { skip: !openSalesDocumentResult.isSuccess }).data
    const [closeSalesDocument] = SalesApi.useCloseSalesDocumentMutation()
    const [saveSalesDocument] = SalesApi.useSaveSalesDocumentMutation()
    const [setSalesDocumentStatus] = SalesApi.useSetSalesDocumentStatusMutation()

    const saveConfiguration = useSaveConfiguration()

    const params = useParams()

    const forms = useProjectForms()

    useEffect(() => {
        openSalesDocument(params.salesDocumentId!)
        return () => {
            closeSalesDocument()
        }
    }, [openSalesDocument, closeSalesDocument, params.salesDocumentId])

    const shouldBlockNavigation: ShouldBlock = useCallback(
        args => (salesDocument ? !salesDocumentIsSaved && !args.nextLocation.pathname.includes(salesDocument.salesDocumentId) : false),
        [salesDocument, salesDocumentIsSaved]
    )

    if (!salesDocument) return null

    const save = async () => {
        if (!configurationIsSaved) {
            await saveConfiguration()
        }
        await saveSalesDocument()
    }

    const numberOfInvalidInputs = forms.reduce(
        (result, form) => result + GenericFormUtils.getNumberOfInvalidInputs(form.formProps.inputs, salesDocument.properties),
        0
    )

    const tabs = [
        {
            cypressId: "tab.details",
            label: L10n.format(TranslationKeys.pages.project.tabs.details),
            route: generatePath(RouterPaths.PROJECT_DETAILS, { salesDocumentId: salesDocument.salesDocumentId }),
            icon:
                numberOfInvalidInputs > 0 ? (
                    <Badge badgeContent={numberOfInvalidInputs} color="error" sx={ProjectPageStyles.badge} />
                ) : (
                    <Icon component={CheckCircle} color="success" />
                )
        },
        {
            cypressId: "tab.catalog",
            label: L10n.format(TranslationKeys.pages.project.tabs.catalog),
            route: generatePath(RouterPaths.PROJECT_CATALOG, {
                salesDocumentId: salesDocument.salesDocumentId,
                productGroupIds:
                    ProductGroupIds.Catalog +
                    (salesDocument.properties.PRODUCT_CATEGORY
                        ? `+${ProductGroupIds[salesDocument.properties.PRODUCT_CATEGORY as keyof typeof ProductGroupIds]}`
                        : "")
            })
        },
        {
            cypressId: "tab.configuration",
            label: L10n.format(TranslationKeys.pages.project.tabs.configuration),
            route: generatePath(RouterPaths.PROJECT_CONFIGURATION, { salesDocumentId: salesDocument.salesDocumentId })
        },
        {
            cypressId: "tab.composition",
            label: L10n.format(TranslationKeys.pages.project.tabs.composition),
            route: generatePath(RouterPaths.PROJECT_COMPOSITION, { salesDocumentId: salesDocument.salesDocumentId })
        },
        {
            cypressId: "tab.reports",
            label: L10n.format(TranslationKeys.pages.project.tabs.reports),
            route: generatePath(RouterPaths.PROJECT_REPORTS, { salesDocumentId: salesDocument.salesDocumentId })
        }
    ]

    const statusValues = Object.values(SalesDocumentStatusValues).map(value => ({
        value,
        text: SalesUtils.salesDocument.getStatusTranslation(value)!,
        cypressId: value
    }))

    const breadcrumbs: Breadcrumb[] = [
        { label: L10n.format(TranslationKeys.pages.projects.pageName), route: RouterPaths.PROJECTS },
        { label: `${salesDocument.properties.project_name} (${salesDocument.properties.quote_id})` }
    ]

    return (
        <MainContainer sx={ProjectPageStyles.container}>
            <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                <Stack direction="row" spacing={1} alignItems="center" minWidth={300}>
                    <Breadcrumbs breadcrumbs={breadcrumbs} sx={ProjectPageStyles.breadcrumbs} />
                    <ProjectPageInfoIcon />
                </Stack>
                <Stack direction="row" spacing={2} flexShrink={0}>
                    <MenuButton
                        variant="contained"
                        text={L10n.format(TranslationKeys.pages.project.changeStatusButtonLabel)}
                        disabled={readOnly}
                        color="secondary"
                        onSelectItem={setSalesDocumentStatus}
                        value={salesDocument.properties.quote_status}
                        items={statusValues}
                    />
                    <SaveButton
                        label={L10n.format(TranslationKeys.pages.project.saveButtonLabel)}
                        disabled={(salesDocumentIsSaved && configurationIsSaved) || readOnly}
                        shouldBlockNavigation={shouldBlockNavigation}
                        shouldBlockUnload={!salesDocumentIsSaved}
                        onClick={save}
                    />
                </Stack>
            </Stack>

            <Tabs tabs={tabs} tabsStyles={ProjectPageStyles.tabStyles}>
                <Outlet />
            </Tabs>
        </MainContainer>
    )
}
