import React, { HTMLProps } from "react"
import Box from "@mui/material/Box"
import { BoxProps } from "@mui/material"

interface ImageProps extends HTMLProps<HTMLPictureElement> {
    src?: string
    fallbackImageProps?: BoxProps<"img">
}

export default function Image({ src, fallbackImageProps, ...props }: Readonly<ImageProps>) {
    return (
        <picture {...props}>
            <source srcSet={src} />
            <Box style={props.style} component="img" {...fallbackImageProps} />
        </picture>
    )
}
