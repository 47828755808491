import { Filter, Selection, Sort } from "@encoway/c-services-js-client"
import { Characteristics, ProductGroupIds } from "../../../../features/catalog/catalog.constants"
import { Characteristics as CharacteristicsFilter } from "@encoway/c-services-js-client/dist/esm/catalog/characteristics"
import { Settings } from "react-slick"
import React from "react"
import { NextArrow } from "./NextArrow"
import { PrevArrow } from "./PrevArrow"

const productGroupFilter = Filter.productGroupFilter().id(ProductGroupIds.News)
const characteristicsFilter = new CharacteristicsFilter().all()
const sort = new Sort().characteristic(Characteristics.Date.id).desc(true)

const sliderSettings: Settings = {
    dots: true,
    infinite: false,
    lazyLoad: "anticipated",
    speed: 500,
    slidesToShow: 8,
    slidesToScroll: 1,
    variableWidth: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
        {
            breakpoint: 3200,
            settings: {
                slidesToShow: 7,
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 2800,
            settings: {
                slidesToShow: 6,
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 2400,
            settings: {
                slidesToShow: 5,
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 2000,
            settings: {
                slidesToShow: 4,
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 1600,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ]
}

export const NewsWidgetConstants = {
    catalogSelection: new Selection().filter(productGroupFilter).limit(100).offset(0).characteristics(characteristicsFilter).sort(sort),
    sliderSettings: sliderSettings
}
