import { L10n } from "@encoway/l10n"
import { GenericInput } from "../../../../components/genericForm/GenericForm"
import { LineItemProperties } from "../../sales.constants"
import TranslationKeys from "../../../translations/TranslationKeys"

export default function useCreateCustomArticleFormInputs(): GenericInput[] {
    return [
        {
            id: LineItemProperties.PRODUCT_NAME,
            label: L10n.format(TranslationKeys.lineItem.properties.productName),
            mandatory: true,
            maxLength: 50
        },
        {
            id: LineItemProperties.SHORT_TEXT,
            label: L10n.format(TranslationKeys.lineItem.properties.shortText),
            mandatory: true,
            maxLength: 50
        },
        {
            id: LineItemProperties.PLAIN_LIST_PRICE,
            label: L10n.format(TranslationKeys.lineItem.properties.plainListPrice),
            mandatory: true,
            maxLength: 50,
            type: "number"
        },
        {
            id: LineItemProperties.PLAIN_COST,
            label: L10n.format(TranslationKeys.lineItem.properties.plainCost),
            mandatory: true,
            maxLength: 50,
            type: "number"
        }
    ]
}
