import { Stack, Typography, TypographyProps } from "@mui/material"
import { L10n } from "@encoway/l10n"
import TranslationKeys from "../../features/translations/TranslationKeys"

const ELLIPSIS = "..."

interface TextBoxProps extends TypographyProps {
    text: string
    expand?: boolean
    onExpansionChange?: (expand: boolean) => void
    characterLimit?: number
    isExpandable?: boolean
    buttonProps?: Omit<TypographyProps, "onClick">
}

export default function TextBox({
    text,
    isExpandable = true,
    expand = false,
    onExpansionChange,
    characterLimit,
    buttonProps,
    ...props
}: Readonly<TextBoxProps>) {
    const textIsTruncated = !expand && characterLimit && text.length > characterLimit
    const truncatedText = textIsTruncated ? text.slice(0, characterLimit - ELLIPSIS.length) + ELLIPSIS : text
    return (
        <Stack>
            <Typography {...props}>{truncatedText}</Typography>
            {isExpandable && (textIsTruncated || expand) ? (
                <Typography
                    {...buttonProps}
                    onClick={event => {
                        event.stopPropagation()
                        onExpansionChange?.(!expand)
                    }}
                >
                    {expand ? L10n.format(TranslationKeys.components.textBox.truncate) : L10n.format(TranslationKeys.components.textBox.expand)}
                </Typography>
            ) : null}
        </Stack>
    )
}
