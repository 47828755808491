import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Slider from "react-slick"
import { CatalogApi } from "../../../../features/catalog/catalog.api"
import { ErrorAlert } from "../../../../features/error/components/errorAlert/ErrorAlert"
import NewsCard from "./newsCard/NewsCard"
import { NewsWidgetConstants } from "./NewsWidget.constants"
import Typography from "@mui/material/Typography"
import { NewsWidgetStyles } from "./NewsWidget.styles"

export default function NewsWidget() {
    const productsQuery = CatalogApi.useProductsQuery(NewsWidgetConstants.catalogSelection)
    const news = productsQuery.data?.products ?? []
    if (productsQuery.isError) {
        return <ErrorAlert error={productsQuery.error.message} onRetry={productsQuery.refetch} />
    } else {
        return (
            <Typography component="div" sx={NewsWidgetStyles.sliderStyle}>
                <Slider {...NewsWidgetConstants.sliderSettings}>
                    {productsQuery.isLoading && <NewsCard />}
                    {productsQuery.isSuccess && news.map(product => <NewsCard key={product.id} product={product} />)}
                </Slider>
            </Typography>
        )
    }
}
