import { LineItemProperties } from "../../../../../../features/sales/sales.constants"
import { ProjectCompositionView } from "../../constants/ProjectCompositionView"

export const lineItemGridColumns = [
    LineItemProperties.ABB_LINE_ITEM_ID,
    LineItemProperties.CONFIGURATION_NAME,
    LineItemProperties.CREATED_BY,
    LineItemProperties.CREATED_AT,
    LineItemProperties.MODIFIED_AT,
    LineItemProperties.MODIFIED_BY,
    LineItemProperties.ARTICLE_ID,
    LineItemProperties.CALCULATED_TRANSFER_PRICE,
    LineItemProperties.SALES_PRICE_WITH_CURRENCY,
    LineItemProperties.PLAIN_LIST_PRICE,
    LineItemProperties.PLAIN_LIST_PRICE_WITH_CURRENCY,
    LineItemProperties.TRANSFER_PRICE_DISCOUNTED_UNIT_PRICE,
    LineItemProperties.MULTIPLIER,
    LineItemProperties.PRICEGROUP,
    LineItemProperties.TOTAL_LIST_PRICE,
    LineItemProperties.PLAIN_COST,
    LineItemProperties.PLAIN_COST_WITH_CURRENCY,
    "option",
    LineItemProperties.CALCULATED_TOTAL_TRANSFER_PRICE,
    LineItemProperties.TRANSFER_PRICE_DISCOUNT_PERCENTAGE,
    LineItemProperties.TRANSFER_PRICE_DISCOUNT_ACCUMULATED_PERCENTAGE,
    LineItemProperties.TRANSFER_PRICE_DISCOUNTED_TOTAL_PRICE,
    LineItemProperties.TOTAL_COST,
    LineItemProperties.GROSS_MARGIN,
    LineItemProperties.NET_MARGIN,
    LineItemProperties.SALES_PRICE_MULTIPLIER,
    LineItemProperties.SALES_PRICE_ACCUMULATED_MULTIPLIER,
    LineItemProperties.TOTAL_SALES_PRICE,
    LineItemProperties.SALES_PRICE_DISCOUNT_PERCENTAGE,
    LineItemProperties.SALES_PRICE_ACCUMULATED_DISCOUNT_PERCENTAGE,
    LineItemProperties.DISCOUNTED_SALES_PRICE,
    LineItemProperties.DISCOUNTED_TOTAL_SALES_PRICE,
    LineItemProperties.LOCAL_PRODUCTION_COST,
    LineItemProperties.TOTAL_LOCAL_PRODUCTION_COST,
    LineItemProperties.LOCAL_GROSS_MARGIN,
    LineItemProperties.LOCAL_GROSS_PROFIT
]

const MANUFACTURING_UNIT_VIEW_COLUMNS = [
    LineItemProperties.CALCULATED_TOTAL_TRANSFER_PRICE,
    LineItemProperties.TRANSFER_PRICE_DISCOUNT_ACCUMULATED_PERCENTAGE,
    LineItemProperties.TRANSFER_PRICE_DISCOUNTED_TOTAL_PRICE,
    LineItemProperties.TRANSFER_PRICE_DISCOUNT_PERCENTAGE,
    LineItemProperties.TOTAL_COST,
    LineItemProperties.GROSS_MARGIN,
    LineItemProperties.NET_MARGIN
]

const END_TO_END_VIEW_COLUMNS = [LineItemProperties.TOTAL_COST, LineItemProperties.GROSS_MARGIN, LineItemProperties.TRANSFER_PRICE_DISCOUNTED_TOTAL_PRICE]

const SALES_UNIT_VIEW_COLUMNS = [
    LineItemProperties.CALCULATED_TOTAL_TRANSFER_PRICE,
    LineItemProperties.TRANSFER_PRICE_DISCOUNT_ACCUMULATED_PERCENTAGE,
    LineItemProperties.TRANSFER_PRICE_DISCOUNTED_TOTAL_PRICE,
    LineItemProperties.SALES_PRICE_MULTIPLIER,
    LineItemProperties.SALES_PRICE_ACCUMULATED_MULTIPLIER,
    LineItemProperties.TOTAL_SALES_PRICE,
    LineItemProperties.SALES_PRICE_DISCOUNT_PERCENTAGE,
    LineItemProperties.SALES_PRICE_ACCUMULATED_DISCOUNT_PERCENTAGE,
    LineItemProperties.DISCOUNTED_SALES_PRICE,
    LineItemProperties.DISCOUNTED_TOTAL_SALES_PRICE,
    LineItemProperties.LOCAL_PRODUCTION_COST,
    LineItemProperties.TOTAL_LOCAL_PRODUCTION_COST,
    LineItemProperties.LOCAL_GROSS_MARGIN,
    LineItemProperties.LOCAL_GROSS_PROFIT
]

const CUSTOMER_VIEW_COLUMNS = [
    LineItemProperties.TOTAL_SALES_PRICE,
    LineItemProperties.SALES_PRICE_ACCUMULATED_DISCOUNT_PERCENTAGE,
    LineItemProperties.DISCOUNTED_TOTAL_SALES_PRICE
]

export const ProjectCompositionColumnMapping = {
    [ProjectCompositionView.ABB_MANUFACTURING_UNIT_VIEW]: MANUFACTURING_UNIT_VIEW_COLUMNS,
    [ProjectCompositionView.ABB_SALES_UNIT_VIEW]: SALES_UNIT_VIEW_COLUMNS,
    [ProjectCompositionView.ABB_END_TO_END_VIEW]: END_TO_END_VIEW_COLUMNS,
    [ProjectCompositionView.ABB_CUSTOMER_VIEW]: CUSTOMER_VIEW_COLUMNS
}
