import TextField, { TextFieldProps } from "@mui/material/TextField"
import { InputLabel, InputLabelProps } from "./Input.Label"

export type InputProps = TextFieldProps & Omit<InputLabelProps, "onChange">

export default function Input({ label, info, renderInfoInDrawer, ...props }: Readonly<InputProps>) {
    const id = label?.replace(" ", "_") + "_input"
    return (
        <TextField
            onChange={event => console.log(event.target.value)}
            data-cy={`TextField:${id}`}
            label={<InputLabel label={label} info={info} renderInfoInDrawer={renderInfoInDrawer} />}
            id={id}
            inputProps={{ "data-cy": `Input.${id}` }}
            autoComplete="on"
            {...props}
        />
    )
}
