import { useState } from "react"
import { Box, Stack, Typography } from "@mui/material"
import { CheckCircle, Error } from "@mui/icons-material"
import ExpandButton from "../../../../../components/buttons/expandButton/ExpandButton"
import Icon from "../../../../../components/./icons/Icon"
import ProjectFormStyles from "./ProjectForm.styles"
import GenericForm, { GenericFormProps } from "../../../../../components/genericForm/GenericForm"

export interface FormProps {
    title: string
    isValid: boolean
    formProps: GenericFormProps
    onBlurInput?: () => void
}

export default function ProjectForm({ title, isValid, onBlurInput, ...props }: Readonly<FormProps>) {
    const [expanded, setExpanded] = useState<boolean>(true)
    return (
        <Stack>
            <Stack direction="row" spacing={3} sx={ProjectFormStyles.header}>
                <ExpandButton expanded={expanded} onToggle={() => setExpanded(prevState => !prevState)} />
                <Typography variant="h3">{title}</Typography>
                {isValid ? <Icon component={CheckCircle} color="success" /> : <Icon component={Error} color="error" />}
            </Stack>
            {expanded ? (
                <Box sx={ProjectFormStyles.contentContainer}>
                    <GenericForm {...props.formProps} columns={2} spacing={5} />
                </Box>
            ) : null}
        </Stack>
    )
}
