import React from "react"
import { OpenIdConnect } from "@encoway/sales-showroom-auth"
import AbbOverlaySpinner from "../projectPage/components/projectConfiguration/components/cui/components/AbbSpinner/AbbOverlaySpinner"
import { ConanBase } from "./components/ConanBase/ConanBase"
import { EnvironmentApi } from "../../features/environment/environment.api"

export const ConanPage = () => {
    const { data: environment } = EnvironmentApi.usePublicEnvironmentQuery()
    if (environment === undefined) {
        return <AbbOverlaySpinner />
    } else {
        return (
            <OpenIdConnect
                clientId={environment.clientId}
                realm=""
                authenticationServerUrl={environment.issuerUri}
                expectedIssuerUrl={environment.expectedIssuerUri || environment.issuerUri}
                redirectUri={window.location.protocol + "//" + window.location.host + window.location.pathname}
            >
                <ConanBase />
            </OpenIdConnect>
        )
    }
}
