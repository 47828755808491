import { Info } from "@mui/icons-material"
import Icon, { IconProps } from "../.././icons/Icon"
import { ReactNode, useState } from "react"
import { merge } from "lodash"
import { Drawer } from "@mui/material"

export interface InfoIconProps extends Omit<IconProps, "onClick"> {
    renderInfoInDrawer?: boolean
    children: ReactNode
}

export default function InfoIcon({ renderInfoInDrawer, children, ...iconProps }: Readonly<InfoIconProps>) {
    const [isOpen, setIsOpen] = useState(false)
    const openDrawer = () => setIsOpen(true)
    const closeDrawer = () => setIsOpen(false)

    const tooltipProps = renderInfoInDrawer
        ? undefined
        : merge({ title: children, slotProps: { tooltip: { sx: { maxWidth: "inherit" } } } }, iconProps.tooltipProps)

    return (
        <>
            <Icon component={Info} fontSize="small" {...iconProps} tooltipProps={tooltipProps} onClick={renderInfoInDrawer ? openDrawer : undefined} />
            {renderInfoInDrawer && (
                <Drawer open={isOpen} onClose={closeDrawer} anchor="right">
                    {children}
                </Drawer>
            )}
        </>
    )
}
