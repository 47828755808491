const InformationStyles = {
    wrapper: {
        display: {
            xs: "none",
            sm: "flex"
        },
        height: {
            xs: "22px",
            sm: "33px"
        },
        alignItems: "center"
    },
    text: {
        mr: 2
    }
}

export default InformationStyles
