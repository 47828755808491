import DialogTitle from "@mui/material/DialogTitle"
import DialogContent from "@mui/material/DialogContent"
import DialogContentText from "@mui/material/DialogContentText"
import MuiDialog, { DialogProps as MuiDialogProps } from "@mui/material/Dialog"
import { Divider, Stack, Typography } from "@mui/material"
import theme from "../../App.theme"
import { Close } from "@mui/icons-material"
import DialogStyles from "./Dialog.styles"
import { ReactElement } from "react"
import DialogActions, { ButtonPropsWithLabel, DialogActionsProps } from "./dialogActions/DialogActions"
import TranslationKeys from "../../features/translations/TranslationKeys"
import IconButton from "@mui/material/IconButton"

export interface DefaultDialogActionsProps {
    onCancel: () => void
    onConfirm: () => void
    disableConfirm?: boolean
    cancelButtonLabel?: string
    confirmButtonLabel?: string
}

export interface DialogProps extends MuiDialogProps {
    title: string
    icon?: ReactElement
    message?: string
    withDividers?: boolean
    onClose: () => void
    defaultDialogActions?: DefaultDialogActionsProps
    customDialogActions?: DialogActionsProps
    interpretMessageAsDangerouslySetInnerHTML?: boolean
}

export default function Dialog({
    title,
    icon,
    message,
    withDividers,
    onClose,
    defaultDialogActions,
    customDialogActions,
    interpretMessageAsDangerouslySetInnerHTML,
    children,
    ...props
}: Readonly<DialogProps>) {
    const showFooter = defaultDialogActions || customDialogActions

    const defaultCancelButton: ButtonPropsWithLabel = {
        label: defaultDialogActions?.cancelButtonLabel ?? TranslationKeys.components.dialog.defaultCancelButtonLabel,
        variant: "contained",
        onClick: defaultDialogActions?.onCancel,
        sx: DialogStyles.grayButton
    }
    const defaultConfirmButton: ButtonPropsWithLabel = {
        label: defaultDialogActions?.confirmButtonLabel ?? TranslationKeys.components.dialog.defaultConfirmButtonLabel,
        variant: "contained",
        type: "submit",
        onClick: defaultDialogActions?.onConfirm,
        disabled: defaultDialogActions?.disableConfirm,
        sx: DialogStyles.blackButton,
        autoFocus: true
    }

    return (
        <MuiDialog data-cy={`MuiDialog`} onClose={onClose} {...props}>
            <DialogTitle component={Stack} direction="row" spacing={1} sx={DialogStyles.title}>
                <Stack direction="row" alignItems="center" spacing={1}>
                    {icon}
                    <Typography variant="h4">{title}</Typography>
                </Stack>
                <IconButton onClick={onClose} sx={{ color: theme.palette.text.primary }}>
                    <Close fontSize="small" />
                </IconButton>
            </DialogTitle>
            {withDividers ? <Divider sx={DialogStyles.divider} /> : null}
            <DialogContent sx={DialogStyles.content}>
                {message && (
                    <DialogContentText
                        data-cy="MuiDialog.context.content.message"
                        {...(interpretMessageAsDangerouslySetInnerHTML ? { dangerouslySetInnerHTML: { __html: message } } : { children: message })}
                    />
                )}
                {children}
            </DialogContent>
            {showFooter ? (
                <>
                    {withDividers ? <Divider sx={DialogStyles.divider} /> : null}
                    {customDialogActions ? <DialogActions {...customDialogActions} /> : <DialogActions actions={[defaultCancelButton, defaultConfirmButton]} />}
                </>
            ) : null}
        </MuiDialog>
    )
}
