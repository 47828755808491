import { MouseEvent, useContext } from "react"
import { GridRenderCellParams } from "@mui/x-data-grid-pro"
import { L10n } from "@encoway/l10n"
import ExpandButton from "../../../../../../../components/buttons/expandButton/ExpandButton"
import { BomNode, LineItemDataGridContext } from "../../LineItemsDataGrid"
import TranslationKeys from "../../../../../../../features/translations/TranslationKeys"
import { ProductNameCellStyles } from "./ProductNameCell.styles"
import SalesApi from "../../../../../../../features/sales/sales.api"
import { Stack, Tooltip, Typography } from "@mui/material"
import { useAppSelector } from "../../../../../../../store/store"
import SalesUtils from "../../../../../../../features/sales/sales.utils"

export const renderProductNameCell = (params: GridRenderCellParams<BomNode>) => <ProductNameCell {...params} />

function ProductNameCell(params: Readonly<GridRenderCellParams<BomNode>>) {
    const { onConfigure } = useContext(LineItemDataGridContext)!
    const readOnly = useAppSelector(state => state.sales.salesDocumentReadOnly)
    const [expandLineItem] = SalesApi.useExpandLineItemMutation()
    const [collapseLineItem] = SalesApi.useCollapseLineItemMutation()

    const toggle = (event: MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation()
        if (params.row.expanded) {
            collapseLineItem(params.row.lineItem.lineItemId)
        } else {
            expandLineItem(params.row.lineItem.lineItemId)
        }
    }

    const onClick = params.row.lineItem.properties.configurable ? () => onConfigure(params.row.lineItem, readOnly) : undefined
    const withExpandButton = !params.row.lineItem.properties.isLeaf && params.row.level > 0
    const sx = ProductNameCellStyles(params.row, withExpandButton)
    const value =
        params.row.lineItem.properties.FOLDER_TYPE === "COMMON_OPTIONS"
            ? L10n.format(TranslationKeys.pages.project.composition.commonFolder)
            : SalesUtils.lineItems.getLineItemLabel(params.row.lineItem)

    return (
        <Stack direction="row" sx={sx.stack}>
            {withExpandButton && <ExpandButton expanded={params.row.expanded} onToggle={toggle} />}
            <Tooltip title={value ?? ""}>
                <Typography variant="body2" onClick={onClick} sx={sx.typography}>
                    {value}
                </Typography>
            </Tooltip>
        </Stack>
    )
}
