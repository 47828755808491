import { GenericInput } from "../../../../components/genericForm/GenericForm"
import { UserInformation } from "../../../oidc/hooks/useUserInformation"
import { LineItemProperties } from "../../sales.constants"
import { OidcUtils } from "../../../oidc/oidc.utils"
import { Characteristics } from "../../../catalog/catalog.constants"
import TranslationKeys from "../../../translations/TranslationKeys"
import { translateIfDefined } from "../../../translations/translations.utils"

export const filterInput = (input: GenericInput, userInformation?: UserInformation): boolean => {
    switch (input.id) {
        case LineItemProperties.PLAIN_COST:
            return OidcUtils.hasUpdateCostPricesRole(userInformation)
        case LineItemProperties.PLAIN_LIST_PRICE:
            return OidcUtils.hasUpdateListPricesRole(userInformation)
        default:
            return true
    }
}

export const getProductCategoryInfoMessage = (productCategory?: string) => {
    switch (productCategory) {
        case Characteristics.ProductCategory.possibleValues.DC:
            return translateIfDefined(TranslationKeys.lineItem.newArticle.dialog.infoMessageDC)
        case Characteristics.ProductCategory.possibleValues.MV:
            return translateIfDefined(TranslationKeys.lineItem.newArticle.dialog.infoMessageMV)
        case Characteristics.ProductCategory.possibleValues.LV:
            return translateIfDefined(TranslationKeys.lineItem.newArticle.dialog.infoMessageLV)
        default:
            return translateIfDefined(TranslationKeys.lineItem.newArticle.dialog.infoMessageNoProductCategorySet)
    }
}
