import { useCallback, useMemo, useState } from "react"
import { Button, Portal, Stack, Typography } from "@mui/material"
import { L10n } from "@encoway/l10n"
import { Product, ProductGroup } from "@encoway/c-services-js-client"
import ProductQuickSelectionDataGrid from "./productQuickSelectionDataGrid/ProductQuickSelectionDataGrid"
import TranslationKeys from "../../../../../features/translations/TranslationKeys"
import CatalogUtils from "../../../../../features/catalog/catalog.utils"
import { Characteristics } from "../../../../../features/catalog/catalog.constants"
import Container from "@mui/material/Container"
import ExpandButton from "../../../../../components/buttons/expandButton/ExpandButton"
import { useAppSelector } from "../../../../../store/store"
import { isEmptySelection, selectionToArray, updateSelection } from "./ProductQuickSelection.utils"
import SalesApi from "../../../../../features/sales/sales.api"
import useDialog from "../../../../../components/dialog/useDialog"
import { AddToLineupDialog } from "../addToLineupDialog/AddToLineupDialog"
import { CatalogApi } from "../../../../../features/catalog/catalog.api"
import ProductFilters from "../../../../../features/catalog/components/productFilters/ProductFilters"
import { useProductFilters } from "../../../../../features/catalog/hooks/useProductFilters"
import { ProductSelectionPosition } from "../ProductSelection.constants"
import { QueryStatus } from "@reduxjs/toolkit/query"

interface ProductQuickSelectionProps {
    productGroup: ProductGroup
    searchValue?: string
}

export type ProductSelectionData = {
    [productId: string]: {
        articleName?: string
        quantity: number
    }
}

export type ProductSelection = {
    [group: string]: ProductSelectionData
}

export function ProductQuickSelection({ productGroup, searchValue }: Readonly<ProductQuickSelectionProps>) {
    const [productSelection, setProductSelection] = useState<ProductSelection>({})
    const [expandedProductGroups, setExpandedProductGroups] = useState<string[]>([])
    const readOnly = useAppSelector(state => state.sales.salesDocumentReadOnly)
    const [addItems] = SalesApi.useAddItemsMutation()
    const [checkLineupCompatibility, result] = SalesApi.useCheckLineupCompatibilityMutation()
    const addToLineupDialog = useDialog()
    const subGroupsQuery = CatalogApi.useSubGroupsQuery(productGroup.id)
    const productFilters = useProductFilters(productGroup.id, searchValue)

    const productGroups = useMemo(() => {
        return (productGroup ? [productGroup] : []).concat(subGroupsQuery.data?.productGroups ?? [])
    }, [productGroup, subGroupsQuery.data?.productGroups])

    const updateProductQuantity = useCallback((productGroupId: string, product: Product, quantity: number) => {
        setProductSelection((prevState: ProductSelection) => updateSelection(prevState, productGroupId, product, quantity))
    }, [])

    const addProducts = async (parentId?: string, newLineupName?: string) => {
        const articlesToInsert = selectionToArray(productSelection)
        const position = ProductSelectionPosition.LAST
        await addItems({ articlesToInsert, parentId, position, newLineupName }).unwrap()
        setProductSelection({})
    }

    const onClickAddProductsButton = async () => {
        const articleIds = selectionToArray(productSelection).map(product => product.articleId)
        const response = await checkLineupCompatibility({ articleIds }).unwrap()
        if (response.lineupCompatibleProducts.length) {
            addToLineupDialog.open()
        } else {
            await addProducts().catch()
        }
    }

    const toggleProductGroup = (productGroupId: string) => {
        setExpandedProductGroups(prevState =>
            prevState.includes(productGroupId) ? prevState.filter(id => id !== productGroupId) : prevState.concat(productGroupId)
        )
    }

    const collapseAllProductGroups = () => setExpandedProductGroups([])
    const expandAllProductGroups = () => setExpandedProductGroups(productGroups.map(productGroup => productGroup.id))
    const disableAddProductsButton = useMemo(() => readOnly || isEmptySelection(productSelection), [readOnly, productSelection])

    const lineupNonCompatibleProducts = useMemo(() => {
        return result.status === QueryStatus.fulfilled ? result.data.lineupNonCompatibleProducts : []
    }, [result])

    return (
        <>
            <ProductFilters {...productFilters} />
            <Stack spacing={5} marginTop={3}>
                <Stack direction="row" justifyContent="flex-end" spacing={2}>
                    <Button variant="contained" color="secondary" onClick={expandAllProductGroups}>
                        {L10n.format(TranslationKeys.pages.project.catalog.quickSelection.expandAllProductGroups)}
                    </Button>
                    <Button variant="contained" color="secondary" onClick={collapseAllProductGroups}>
                        {L10n.format(TranslationKeys.pages.project.catalog.quickSelection.collapseAllProductGroups)}
                    </Button>
                </Stack>
                <Stack spacing={5}>
                    {productGroups.map(productGroup => (
                        <Stack key={productGroup.id} spacing={2}>
                            <Stack direction="row" alignItems="flex-start" spacing={1}>
                                <ExpandButton expanded={expandedProductGroups.includes(productGroup.id)} onToggle={() => toggleProductGroup(productGroup.id)} />
                                <Stack>
                                    <Typography variant="h3">{productGroup.name}</Typography>
                                    <Typography variant="inherit">{CatalogUtils.getCharacteristicValue(productGroup, Characteristics.ShortText.id)}</Typography>
                                </Stack>
                            </Stack>
                            {expandedProductGroups.includes(productGroup.id) && (
                                <ProductQuickSelectionDataGrid
                                    productGroupId={productGroup.id}
                                    characteristicFilters={productFilters.characteristicFilters}
                                    updateProductQuantity={updateProductQuantity}
                                    selectedProducts={productSelection[productGroup.id]}
                                />
                            )}
                        </Stack>
                    ))}
                </Stack>
                <Portal>
                    <Container
                        maxWidth="xl"
                        sx={{ position: "fixed", bottom: 0, left: "50%", right: "50%", transform: "translate(-50%, -50%)", pointerEvents: "none" }}
                    >
                        <Stack justifyContent="flex-end" direction="row" spacing={2} px={3}>
                            <Button
                                disabled={disableAddProductsButton}
                                variant="contained"
                                onClick={onClickAddProductsButton}
                                color="secondary"
                                sx={{ pointerEvents: "initial" }}
                            >
                                {L10n.format(TranslationKeys.pages.project.catalog.quickSelection.addToCompositionButtonLabel)}
                            </Button>
                            {addToLineupDialog.isOpen && (
                                <AddToLineupDialog
                                    onSubmit={addProducts}
                                    lineupNonCompatibleProducts={lineupNonCompatibleProducts}
                                    onClose={addToLineupDialog.close}
                                />
                            )}
                        </Stack>
                    </Container>
                </Portal>
            </Stack>
        </>
    )
}
