import theme from "../../../../../../../App.theme"

export const ArticleIdCellStyles = {
    stack: {
        alignItems: "center",
        cursor: "pointer",
        justifyContent: "space-between",
        maxWidth: "100%"
    },
    text: {
        overflow: "hidden",
        textOverflow: "ellipsis",
        paddingRight: "4px"
    },
    box: {
        padding: 1,
        backgroundColor: theme.palette.background.default,
        borderRadius: 1,
        borderWidth: 2,
        borderColor: theme.palette.divider,
        borderStyle: "solid"
    },
    button: {
        flexShrink: 0
    }
}
