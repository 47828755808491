import { Characteristic, MediaValue } from "@encoway/c-services-js-client"
import { Box, Stack, Typography } from "@mui/material"
import CatalogUtils from "../../catalog.utils"
import { Characteristics } from "../../catalog.constants"
import Image from "../../../../components/image/Image"

interface CharacteristicDrawerContentProps {
    characteristic: Characteristic
}

export function CharacteristicDrawerContent({ characteristic }: Readonly<CharacteristicDrawerContentProps>) {
    const productImage = CatalogUtils.getCharacteristicValue<MediaValue>(characteristic, Characteristics.ProductImage.id)
    const shortText = CatalogUtils.getCharacteristicValue<string>(characteristic, Characteristics.ShortText.id)
    const longText = CatalogUtils.getCharacteristicValue<string>(characteristic, Characteristics.LongText.id)
    return (
        <Stack spacing={3} padding={3}>
            <Typography variant="h3">{characteristic.name}</Typography>
            {productImage && <Image src={productImage?.uri + "&resolution=medium"} width={400} />}
            {shortText && <Typography fontWeight="bold">{shortText}</Typography>}
            {longText && <Box dangerouslySetInnerHTML={{ __html: longText }} />}
        </Stack>
    )
}
