import { useEffect } from "react"
import { DropdownProps } from "@encoway/cui-configurator-components"
import { useAppDispatch } from "../../../../../../../../store/store"
import { setDefaultLayout } from "./AbbDefaultLayout.utils"

export function AbbDefaultLayout(props: DropdownProps<any>) {
    const dispatch = useAppDispatch()

    useEffect(() => {
        const layout = props.data.selectedValues?.at(0)?.value
        setDefaultLayout(layout, dispatch)
    }, [dispatch, props.data.selectedValues])

    return null
}
