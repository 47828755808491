import { IconButton } from "@mui/material"
import ArrowForwardIcon from "@mui/icons-material/ArrowForward"
import React from "react"
import { NextArrowStyles } from "./NextArrow.styles"

export const NextArrow = (props: any) => {
    const { style, onClick } = props
    return (
        <IconButton onClick={onClick} style={NextArrowStyles.root(style)}>
            <ArrowForwardIcon />
        </IconButton>
    )
}
