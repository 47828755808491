import { useMemo } from "react"
import { GenericInput } from "../../../../components/genericForm/GenericForm"
import { useSalesDocumentPropertyInput } from "../../hooks/useSalesDocumentPropertyInput"
import { SalesDocumentProperties } from "../../sales.constants"
import { Characteristics } from "../../../catalog/catalog.constants"
import { SalesDocumentPropertyInputDefinitions } from "../../constants/SalesDocumentPropertyInputDefinitions"

export default function useNewSalesDocumentFormInputs(skip: boolean): GenericInput[] {
    const industrySegmentInput = useSalesDocumentPropertyInput(SalesDocumentProperties.INDUSTRY_SEGMENT, Characteristics.IndustrySegment.id, false, false, skip)
    const currencyInput = useSalesDocumentPropertyInput(
        SalesDocumentProperties.TRANSFER_PRICE_CURRENCY,
        Characteristics.TransferPriceCurrency.id,
        false,
        true,
        skip
    )
    const productCategoryInput = useSalesDocumentPropertyInput(SalesDocumentProperties.PRODUCT_CATEGORY, Characteristics.ProductCategory.id, false, true, skip)
    const localSalesUnitInput = useSalesDocumentPropertyInput(SalesDocumentProperties.LOCAL_SALES_UNIT, Characteristics.LocalSalesUnit.id, false, true, skip)

    return useMemo(() => {
        return [
            SalesDocumentPropertyInputDefinitions.PROJECT_NAME,
            productCategoryInput,
            industrySegmentInput,
            localSalesUnitInput,
            currencyInput,
            SalesDocumentPropertyInputDefinitions.SALESFORCE_OPPORTUNITY_NUMBER
        ]
    }, [currencyInput, industrySegmentInput, productCategoryInput, localSalesUnitInput])
}
