import { GridRenderCellParams } from "@mui/x-data-grid-pro"
import { Box, Button, Stack, Tooltip } from "@mui/material"
import { BomNode } from "../../LineItemsDataGrid"
import useDialog from "../../../../../../../components/dialog/useDialog"
import { ArticleIdCellStyles } from "./ArticleIdCellStyles"
import { Queue } from "@mui/icons-material"
import Dialog from "../../../../../../../components/dialog/Dialog"
import { L10n } from "@encoway/l10n"
import TranslationKeys from "../../../../../../../features/translations/TranslationKeys"

export default function ArticleIdCell(params: GridRenderCellParams<BomNode>) {
    const dialog = useDialog()

    const onClickCopyButton = () => {
        navigator.clipboard.writeText(params.value)
        dialog.close()
    }

    return params.value ? (
        <>
            <Tooltip title={params.value}>
                <Stack direction="row" spacing={1} onClick={dialog.open} sx={ArticleIdCellStyles.stack}>
                    <span style={ArticleIdCellStyles.text}>{params.value}</span>
                    <Queue fontSize="small" />
                </Stack>
            </Tooltip>
            <Dialog
                maxWidth="xl"
                title={L10n.format(TranslationKeys.pages.project.composition.copyTypeCode)}
                icon={<Queue fontSize="small" />}
                open={dialog.isOpen}
                onClose={dialog.close}
                withDividers
            >
                <Stack spacing={1}>
                    <span>{L10n.format(TranslationKeys.pages.project.composition.copyTypeCode)}</span>
                    <Stack direction="row" alignItems="end" spacing={1}>
                        <Box sx={ArticleIdCellStyles.box} dangerouslySetInnerHTML={{ __html: params.value.replaceAll("+", "<wbr>+") }} />
                        <Button variant="contained" color="secondary" size="large" onClick={onClickCopyButton} sx={ArticleIdCellStyles.button}>
                            <Stack direction="row" spacing={1} alignItems="center">
                                <Queue />
                                <span>{L10n.format(TranslationKeys.pages.project.composition.copyTypeCode)}</span>
                            </Stack>
                        </Button>
                    </Stack>
                </Stack>
            </Dialog>
        </>
    ) : null
}
