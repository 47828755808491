const MobileMenuStyles = {
    wrapper: {
        flexGrow: 0,
        justifyContent: "center",
        display: {
            xs: "flex",
            sm: "flex",
            md: "none"
        }
    },
    menu: {
        display: {
            xs: "block",
            md: "none"
        }
    },
    menuItem: {
        "&[aria-current]": {
            fontWeight: "bold"
        }
    }
}

export default MobileMenuStyles
