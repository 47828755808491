import { VISUALIZATION_IMAGE_ORTHOGRAPHIC_PREFIX, VISUALIZATION_IMAGE_PREFIX } from "./visualization.constants"

export function createRenderingsResult(renderings?: Record<string, string>, orthographicRenderings?: Record<string, string>): Record<string, string> {
    return {
        ...(renderings ? getRenderingResult(renderings) : {}),
        ...(orthographicRenderings ? getRenderingResult(orthographicRenderings, true) : {})
    }
}

function getRenderingResult(renderings: Record<string, string>, orthographic?: boolean) {
    return Object.entries(renderings).reduce((result, [key, value]) => ({ ...result, [getVisualizationImageKey(key, orthographic)]: value }), {})
}

function getVisualizationImageKey(imageName: string, orthographic?: boolean) {
    const prefix = orthographic ? VISUALIZATION_IMAGE_PREFIX + VISUALIZATION_IMAGE_ORTHOGRAPHIC_PREFIX : VISUALIZATION_IMAGE_PREFIX
    return prefix + imageName.replaceAll("-", "_").toUpperCase()
}
