import theme from "../../../../App.theme"

export const NextArrowStyles = {
    root: (style: any) => ({
        ...style,
        display: "block",
        color: theme.palette.text.secondary,
        position: "absolute",
        right: "-25px",
        top: "50%",
        cursor: "pointer",
        zIndex: "100000"
    })
}
