import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import InformationStyles from "./Information.styles"
import { L10n } from "@encoway/l10n"
import SessionExpirationCountdown from "./SessionExpirationCountdown"
import TranslationKeys from "../../../../../features/translations/TranslationKeys"
import { EnvironmentApi } from "../../../../../features/environment/environment.api"
import { useUserInformation } from "../../../../../features/oidc/hooks/useUserInformation"
import { useLogout } from "../../../../../features/oidc/hooks/useLogout"

export default function Information() {
    const environment = EnvironmentApi.usePublicEnvironmentQuery().data
    const userInformation = useUserInformation()
    const logout = useLogout()
    const name = userInformation ? userInformation.username : ""
    return (
        <Box component="div" sx={InformationStyles.wrapper}>
            <Typography variant="caption" sx={InformationStyles.text}>
                <Box data-cy="username">
                    {L10n.format(TranslationKeys.components.header.welcome)} {name}
                </Box>
                <Box>
                    <SessionExpirationCountdown logout={logout} />
                </Box>
                <Box>
                    <Box component="span" color="primary.main">
                        {environment?.systemEnvironment.toUpperCase()}
                    </Box>
                    &nbsp;
                    {L10n.format(TranslationKeys.components.header.title)} {environment?.version}
                </Box>
            </Typography>
        </Box>
    )
}
