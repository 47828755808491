import { Tabs, TabsProps } from "@encoway/cui-configurator-components"
import AbbTabsStyles from "./AbbTabs.styles"
import { cloneDeep } from "lodash"
import { useMemo } from "react"
import { filterDefaultLayoutContainer } from "./AbbTabs.utils"

export default function AbbTabs(props: Readonly<TabsProps>) {
    const containerTO = useMemo(() => filterDefaultLayoutContainer(cloneDeep(props.data)), [props.data])
    return <Tabs {...{ ...props, data: containerTO }} styles={AbbTabsStyles} />
}
