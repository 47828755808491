import { useSearchParams } from "react-router-dom"
import React, { useCallback, useMemo, useRef, useState } from "react"
import { getLineItem } from "../../../../ProjectConfiguration.utils"
import SalesApi from "../../../../../../../../features/sales/sales.api"
import { ProjectConfigurationSearchParams } from "../../../../ProjectConfiguration.constants"
import { UnitOverviewTableStyles } from "./UnitOverviewTable.styles"
import { DefineUnitOptionsButton } from "./DefineUnitOptionsButton"
import { L10n } from "@encoway/l10n"
import { GridCellEditStopParams, GridCellParams, GridColDef, GridRowParams, GridRowSelectionModel } from "@mui/x-data-grid-pro"
import { UnitOverviewTableColumns } from "./UnitOverviewTable.constants"
import setLineItemCellValue, { getRowId, getRows } from "./UnitOverviewTable.utils"
import DataGrid from "../../../../../../../../components/dataGrid/DataGrid"
import { AbbLineItem } from "../../../../../../../../features/sales/sales.types"
import { Stack } from "@mui/material"
import SalesUtils from "../../../../../../../../features/sales/sales.utils"
import { cloneDeep } from "lodash"

export function UnitOverviewTable() {
    const projectConfiguration = SalesApi.useProjectConfigurationQuery().currentData!
    const [createUnitOptions] = SalesApi.useCreateUnitOptionsMutation()
    const [searchParams, setSearchParams] = useSearchParams()
    const lineItemId = searchParams.get(ProjectConfigurationSearchParams.LINE_ITEM_ID)!
    const [updateLineItem] = SalesApi.useUpdateLineItemMutation()

    const lineItem = useMemo(() => {
        return getLineItem(lineItemId, projectConfiguration)?.lineItem
    }, [lineItemId, projectConfiguration])

    const unitIds = useMemo(() => {
        return lineItem ? SalesUtils.lineItems.getConfigurationInterfaceTargetLineItemIds(lineItem) : undefined
    }, [lineItem])

    const [rowSelectionModel, setRowSelectionModel] = useState<GridRowSelectionModel>(unitIds ?? [])

    const rows: AbbLineItem[] = useMemo(() => {
        return lineItem ? cloneDeep(getRows(lineItem, projectConfiguration)) : []
    }, [lineItem, projectConfiguration])

    const columns: GridColDef[] = useMemo(() => {
        return UnitOverviewTableColumns.filter(column => column.field !== "actions" || lineItem?.properties.ARTICLE_TYPE !== "UNIT_OPTIONS").map(column => ({
            ...column,
            headerName: L10n.format(column.headerName!),
            editable: true,
            valueSetter: (value, row) => setLineItemCellValue(value, row, column.field)
        }))
    }, [lineItem])

    const onDefineOptions = useCallback(() => {
        createUnitOptions({ parentLineItemId: lineItemId, unitLineItemIds: rowSelectionModel as string[] })
            .unwrap()
            .then(lineItemId => setSearchParams({ [ProjectConfigurationSearchParams.LINE_ITEM_ID]: lineItemId }))
            .catch()
    }, [createUnitOptions, lineItemId, rowSelectionModel, setSearchParams])

    const rowSelectModelRef = useRef({ rowSelectModel: [] as GridRowSelectionModel })

    const isRowSelectable = useCallback(
        (params: GridRowParams<AbbLineItem>) => {
            const productName = rows.find(row => row.lineItemId === rowSelectModelRef.current.rowSelectModel[0])?.properties.name
            return !productName || params.row.properties.name === productName
        },
        [rows]
    )

    const onCellEditStop = (params: GridCellEditStopParams<AbbLineItem>) => {
        updateLineItem([params.row.lineItemId, { [params.field]: params.value }])
    }

    const isCellEditable = (params: GridCellParams<AbbLineItem>) => {
        return SalesUtils.lineItems.isEditableLineItemProperty(params.row, params.field)
    }
    return lineItem?.properties.FOLDER_TYPE === "LINEUP" || lineItem?.properties.ARTICLE_TYPE === "UNIT_OPTIONS" ? (
        <Stack sx={UnitOverviewTableStyles.stack}>
            {lineItem?.properties.ARTICLE_TYPE !== "UNIT_OPTIONS" && (
                <DefineUnitOptionsButton
                    variant="contained"
                    color="secondary"
                    onClick={onDefineOptions}
                    disabled={rowSelectionModel.length === 0}
                    numberOfSelectedUnits={rowSelectionModel.length}
                />
            )}
            <DataGrid
                columns={columns}
                rows={rows}
                getRowId={getRowId}
                hideFooter
                disableRowSelectionOnClick
                checkboxSelection={lineItem.properties.FOLDER_TYPE === "LINEUP"}
                onRowSelectionModelChange={model => {
                    // Using ref because isRowSelected (an internal DataGrid method) gets called before we set rowSelectionModel in state
                    rowSelectModelRef.current.rowSelectModel = model
                    setRowSelectionModel(model)
                }}
                isRowSelectable={isRowSelectable}
                rowSelectionModel={rowSelectionModel}
                isCellEditable={isCellEditable}
                onCellEditStop={onCellEditStop}
            />
        </Stack>
    ) : null
}
