import { useMemo } from "react"
import { GenericInput, InputType } from "../../../components/genericForm/GenericForm"
import { CatalogApi } from "../../catalog/catalog.api"
import CatalogUtils from "../../catalog/catalog.utils"
import SalesApi from "../sales.api"
import { CurrencyPermissions } from "../sales.api.types"
import { CharacteristicDrawerContent } from "../../catalog/components/characteristicDrawerContent/CharacteristicDrawerContent"

const filterByPermissions = (characteristicId: string, value: string, permissions?: CurrencyPermissions) => {
    if (!permissions) {
        return false
    } else {
        const permissionsForCharacteristic: string[] | undefined = permissions[characteristicId as keyof CurrencyPermissions]
        return permissionsForCharacteristic?.includes(value) ?? true
    }
}

export function useSalesDocumentPropertyInput(
    salesDocumentPropertyName: string,
    characteristicId: string,
    mandatory?: boolean,
    unselect?: boolean,
    skip?: boolean
): GenericInput {
    const characteristic = CatalogApi.useCharacteristicQuery(characteristicId, { skip }).data
    const permissions = SalesApi.useCurrencyPermissionsQuery()

    const values = useMemo(() => {
        return characteristic
            ? CatalogUtils.getPossibleValues(characteristic, true)
                  .filter(pv => filterByPermissions(characteristicId, pv.id, permissions.data))
                  .map(pv => ({ value: pv.id, text: pv.value as string }))
            : []
    }, [characteristic, characteristicId, permissions.data])

    return useMemo(
        () => ({
            type: InputType.Dropdown,
            id: salesDocumentPropertyName,
            label: characteristic?.name ?? "",
            mandatory: mandatory,
            unselect: unselect,
            info: characteristic ? CharacteristicDrawerContent({ characteristic }) : undefined,
            renderInfoInDrawer: true,
            values
        }),
        [salesDocumentPropertyName, characteristic, mandatory, unselect, values]
    )
}
