import { useMemo, useState } from "react"
import useCreateCustomArticleFormInputs from "./useCreateCustomArticleFormInputs"
import { L10n } from "@encoway/l10n"
import { filterInput, getProductCategoryInfoMessage } from "./CreateCustomArticleDialog.utils"
import useDialog from "../../../../components/dialog/useDialog"
import { AbbLineItemProperties } from "../../sales.types"
import SalesApi from "../../sales.api"
import { useUserInformation } from "../../../oidc/hooks/useUserInformation"
import Dialog from "../../../../components/dialog/Dialog"
import TranslationKeys from "../../../translations/TranslationKeys"
import { GenericFormUtils } from "../../../../components/genericForm/GenericForm.utils"
import GenericForm from "../../../../components/genericForm/GenericForm"
import { Alert, Stack } from "@mui/material"

export default function CreateCustomArticleDialog(props: Readonly<ReturnType<typeof useDialog>>) {
    const [inputValues, setInputValues] = useState<Partial<AbbLineItemProperties>>({})
    const [addCustomLineItem] = SalesApi.useAddCustomLineItemMutation()
    const inputs = useCreateCustomArticleFormInputs()
    const userInformation = useUserInformation()
    const productCategory = SalesApi.endpoints.salesDocument.useQueryState(undefined, {
        selectFromResult: ({ data }) => ({
            productCategory: data?.properties.PRODUCT_CATEGORY
        })
    }).productCategory
    const updateInputValues = (property: keyof Partial<AbbLineItemProperties>, value: any) => {
        setInputValues(prevState => ({ ...prevState, [property]: value }))
    }
    const filteredInputs = useMemo(() => inputs.filter(input => filterInput(input, userInformation)), [inputs, userInformation])

    const close = () => {
        props.close()
        setInputValues({})
    }

    const submit = () => {
        addCustomLineItem({ ...inputValues, name: inputValues.label }).then(close)
    }

    const infoMessage = getProductCategoryInfoMessage(productCategory)

    return (
        <Dialog
            withDividers
            title={L10n.format(TranslationKeys.lineItem.newArticle.dialog.title)}
            open={props.isOpen}
            onClose={close}
            defaultDialogActions={{
                onConfirm: submit,
                disableConfirm: !GenericFormUtils.isFormValid(filteredInputs, inputValues),
                onCancel: close,
                cancelButtonLabel: L10n.format(TranslationKeys.lineItem.newArticle.dialog.cancelButtonLabel),
                confirmButtonLabel: L10n.format(TranslationKeys.lineItem.newArticle.dialog.submitButtonLabel)
            }}
        >
            <Stack rowGap={2}>
                {infoMessage && <Alert severity="info">{infoMessage}</Alert>}
                <GenericForm
                    inputs={filteredInputs}
                    values={inputValues}
                    update={updateInputValues}
                    validateInput={input => GenericFormUtils.validateInput(input, inputValues)}
                    columns={1}
                    spacing={2}
                />
            </Stack>
        </Dialog>
    )
}
