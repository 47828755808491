import { PropsWithChildren, useCallback, useEffect, useReducer, useState } from "react"
import BusySlice from "../../../../features/busy/busy.slice"
import { Http, useToken } from "@encoway/sales-showroom-auth"
import { SalesService } from "@encoway/sales-api-js-client"
import { Settings } from "../../../../settings"
import { SalesSlice } from "../../../../features/sales/sales.slice"
import { useAppDispatch, useAppSelector } from "../../../../store/store"
import { UnexpectedError } from "../unexpectedError/UnexpectedError"
import { L10n } from "@encoway/l10n"
import TranslationKeys from "../../../../features/translations/TranslationKeys"
import ErrorSlice from "../../../../features/error/error.slice"
import readError from "../../../../features/error/utils/readError"
import ErrorDialog from "../errorDialog/ErrorDialog"

export function ContentWithSalesService({ children }: Readonly<PropsWithChildren<unknown>>) {
    const salesService = useAppSelector(state => state.sales.salesService)
    const [failedToInitializeSalesService, setFailedToInitializeSalesServiceToTrue] = useReducer(() => true, false)
    const [invalidSessionError, setInvalidSessionError] = useState<Error>()

    const tokenFunction = useToken()
    const dispatch = useAppDispatch()

    const handleHeartbeatError = useCallback(
        async (error: any) => {
            if (typeof error === "object" && error.status === 440) {
                setInvalidSessionError({
                    name: L10n.format(TranslationKeys.error.invalidSession.title),
                    message: L10n.format(TranslationKeys.error.invalidSession.message)
                })
            } else {
                dispatch(ErrorSlice.actions.set(await readError(error)))
            }
        },
        [dispatch]
    )

    const createSalesService = useCallback(async () => {
        const http = Http.Bearer(tokenFunction).withCredentialsInclude()
        let service: SalesService
        try {
            service = await SalesService.create(http, Settings.configuration.baseUrl)
        } catch (err) {
            console.warn("destroying previous session to start with a new one")
            await SalesService.destroy(http, Settings.configuration.baseUrl)
            service = await SalesService.create(http, Settings.configuration.baseUrl)
        }
        service.setHeartbeatErrorHandler(handleHeartbeatError)
        return service
    }, [tokenFunction, handleHeartbeatError])

    useEffect(() => {
        dispatch(BusySlice.actions.setBusy())
        createSalesService()
            .then(salesService => dispatch(SalesSlice.actions.setSalesService(salesService)))
            .catch(setFailedToInitializeSalesServiceToTrue)
            .finally(() => dispatch(BusySlice.actions.setIdle()))
    }, [dispatch, createSalesService])

    if (failedToInitializeSalesService) {
        return (
            <UnexpectedError
                title={L10n.format(TranslationKeys.error.failedToInitializeSalesService.title)}
                message={L10n.format(TranslationKeys.error.failedToInitializeSalesService.message)}
            />
        )
    } else if (salesService) {
        return (
            <>
                {children}
                {invalidSessionError && (
                    <ErrorDialog
                        error={invalidSessionError}
                        onClose={() => setInvalidSessionError(undefined)}
                        customDialogActions={{
                            actions: [
                                {
                                    label: TranslationKeys.error.invalidSession.reloadButtonLabel,
                                    onClick: () => window.location.reload(),
                                    variant: "contained"
                                }
                            ]
                        }}
                    />
                )}
            </>
        )
    } else {
        return null
    }
}
