import React, { useEffect } from "react"
import { RootProps } from "@encoway/cui-configurator-components"
import { ComponentFactory } from "@encoway/react-configurator"
import { ComponentName } from "@encoway/cui-configurator-components/src/constants"
import AbbRootStyles from "./AbbRoot.styles"
import { Portal } from "@mui/material"
import { ProjectConfigurationContainerIds } from "../../../../ProjectConfiguration.constants"
import { getFilteredData } from "./AbbRoot.utils"
import { setDefaultLayout } from "../AbbDefaultLayout/AbbDefaultLayout.utils"
import { useAppDispatch } from "../../../../../../../../store/store"
import { CustomConfiguratorComponents } from "../../constants/CustomConfiguratorComponents"
import { ParameterTO } from "@encoway/c-services-js-client"

export default function AbbRoot(props: Readonly<RootProps>) {
    const configurationContainer = document.getElementById(ProjectConfigurationContainerIds.CONFIGURATION)
    const dispatch = useAppDispatch()
    useEffect(() => {
        const mutationObserver = new MutationObserver(() => configurationContainer?.scrollTo({ top: 0, left: 0 }))
        mutationObserver.observe(configurationContainer!, { childList: true, subtree: false, attributes: false })
        return () => mutationObserver.disconnect()
    }, [configurationContainer])
    const filteredData = getFilteredData(props.data)
    const propsWithFilteredData = { ...props, data: filteredData }

    useEffect(() => {
        const parameter = props.data.parameters.find((parameter: ParameterTO) => parameter.viewPort === CustomConfiguratorComponents.DefaultLayout)
        if (parameter) {
            const layout = parameter.selectedValues?.at(0)?.value
            setDefaultLayout(layout, dispatch)
        }
    }, [dispatch, props.data])

    return (
        <Portal container={() => configurationContainer}>
            {props.data?.parameters.filter((x: ParameterTO) => x.viewPort !== CustomConfiguratorComponents.DefaultLayout)?.length > 0 ? (
                <div style={AbbRootStyles}>{ComponentFactory.instanceOf(ComponentName.Section, propsWithFilteredData)}</div>
            ) : (
                ComponentFactory.instanceOf(ComponentName.Tabs, propsWithFilteredData)
            )}
        </Portal>
    )
}
