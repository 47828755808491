import { SxProps, Theme } from "@mui/material"
import { CSSProperties } from "react"

const card: SxProps<Theme> = {
    width: 250,
    height: 350,
    margin: "16px"
}

const image: CSSProperties = {
    width: "100%",
    objectFit: "cover"
}

const fallbackImage: SxProps<Theme> = {
    borderRadius: 3,
    objectFit: "contain",
    width: "100%",
    height: 125
}

const title: SxProps<Theme> = {
    fontSize: 20,
    fontWeight: "bold",
    display: "-webkit-box",
    WebkitLineClamp: "2",
    overflow: "hidden",
    WebkitBoxOrient: "vertical"
}

const timeAgo: SxProps<Theme> = {
    fontSize: 14
}

const box: SxProps<Theme> = {
    display: "-webkit-box",
    WebkitLineClamp: "3",
    overflow: "hidden",
    WebkitBoxOrient: "vertical"
}

export const NewsCardStyles = { card, image, fallbackImage, title, timeAgo, box }
