import { Stack } from "@fluentui/react/lib/Stack"
import { DetailDropdownValueImage } from "./DetailDropdownValueImage"
import { IProcessedStyleSet, mergeStyles } from "@fluentui/react/lib/Styling"
import { DetailDropdownShortText } from "./DetailDropdownShortText"
import { IDetailDropdownOption, IFilterDetailDropdownStyles } from "./AbbFilterDetailDropdown.types"
import { changeValue, renderOption } from "@encoway/cui-configurator-components"
import { mediaLink } from "@encoway/react-configurator"

interface FilterDetailDropdownOptionProps {
    showImage: boolean
    showShortText: boolean
    imageResolution?: string
    mediaLink?: mediaLink
    dropdownOption: IDetailDropdownOption
    changeValue: changeValue
    defaultRender: renderOption
    classNames: IProcessedStyleSet<IFilterDetailDropdownStyles>
}

export const DetailDropdownOption = (props: FilterDetailDropdownOptionProps) => {
    const { showImage, showShortText, imageResolution, dropdownOption, changeValue, defaultRender, classNames } = props

    const optionStyle = {
        marginTop: showImage ? "0.1rem" : undefined,
        overflow: "hidden",
        width: "100%"
    }

    return (
        <Stack
            horizontal
            onClick={() => changeValue?.(dropdownOption)}
            key={dropdownOption.key}
            data-is-focusable
            className={`${classNames.detailOption} ${dropdownOption.selected ? "encoway-cui-dropdown-selected-value" : ""}`}
        >
            <DetailDropdownValueImage
                image={dropdownOption.image}
                imageResolution={imageResolution}
                mediaLink={props.mediaLink}
                className={
                    dropdownOption.shortText && showShortText ? mergeStyles(classNames.imageContainer, { marginLeft: "0.2rem" }) : classNames.imageContainer
                }
                size={"2rem"}
                showImage={showImage}
            />
            <Stack style={optionStyle} tokens={{ childrenGap: "0px" }}>
                <Stack.Item>{defaultRender(dropdownOption, () => {})}</Stack.Item>
                <DetailDropdownShortText classNames={classNames} dropdownOption={dropdownOption} showShortText={showShortText} />
            </Stack>
        </Stack>
    )
}
