import { L10n } from "@encoway/l10n"
import TranslationKeys from "../../translations/TranslationKeys"
import { ElementType } from "react"
import { CheckCircle, Error, QuestionMark, Warning } from "@mui/icons-material"
import { AbbSalesDocumentEntity, AbbSalesDocumentProperty } from "../sales.types"
import { SalesDocumentStatusValues } from "../sales.constants"

export default class SalesDocumentUtils {
    static getMainProperties(project: AbbSalesDocumentEntity) {
        return [
            { name: L10n.format(TranslationKeys.project.properties.createdBy), value: project.properties.quote_created_by },
            { name: L10n.format(TranslationKeys.project.properties.salesforceOpportunityNumber), value: project.properties.salesforce_opportunity_number },
            { name: L10n.format(TranslationKeys.project.properties.destinationCountry), value: project.properties.DESTINATION_COUNTRY },
            { name: L10n.format(TranslationKeys.project.properties.currency), value: project.properties.quote_currency }
        ]
    }

    static isEditableProperty(property: AbbSalesDocumentProperty, salesDocument: AbbSalesDocumentEntity) {
        return !salesDocument.propertyPermissions[property] || salesDocument.propertyPermissions[property]?.includes("UPDATE")
    }

    static getStatusColor(status?: string) {
        switch (status) {
            case SalesDocumentStatusValues.Draft:
                return "error"
            case SalesDocumentStatusValues.Offer:
                return "warning"
            case SalesDocumentStatusValues.Order:
                return "warning"
            case SalesDocumentStatusValues.Closed:
                return "success"
        }
    }

    static getStatusIcon(status?: string): ElementType {
        switch (status) {
            case SalesDocumentStatusValues.Draft:
                return Error
            case SalesDocumentStatusValues.Offer:
                return Warning
            case SalesDocumentStatusValues.Order:
                return Warning
            case SalesDocumentStatusValues.Closed:
                return CheckCircle
            default:
                return QuestionMark
        }
    }

    static getStatusTranslation(status?: string) {
        switch (status) {
            case SalesDocumentStatusValues.Draft:
                return L10n.format(TranslationKeys.project.statusValues.draft)
            case SalesDocumentStatusValues.Offer:
                return L10n.format(TranslationKeys.project.statusValues.offered)
            case SalesDocumentStatusValues.Order:
                return L10n.format(TranslationKeys.project.statusValues.ordered)
            case SalesDocumentStatusValues.Closed:
                return L10n.format(TranslationKeys.project.statusValues.closed)
        }
    }
}
