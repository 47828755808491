import { GenericInput } from "../../../../../components/genericForm/GenericForm"
import { SalesDocumentPropertyInputDefinitions } from "../../../../../features/sales/constants/SalesDocumentPropertyInputDefinitions"

export const generalInformationInputs: GenericInput[] = [
    SalesDocumentPropertyInputDefinitions.QUOTE_ID,
    SalesDocumentPropertyInputDefinitions.CREATED_BY,
    SalesDocumentPropertyInputDefinitions.CREATED_AT,
    SalesDocumentPropertyInputDefinitions.MODIFIED_AT,
    SalesDocumentPropertyInputDefinitions.MODIFIED_BY,
    SalesDocumentPropertyInputDefinitions.ADDITIONAL_INFORMATION
]
