import { createApi, fakeBaseQuery } from "@reduxjs/toolkit/query/react"
import { L10n } from "@encoway/l10n"
import TranslationKeys from "../translations/TranslationKeys"
import { NumberValue, Product } from "@encoway/c-services-js-client"
import CatalogUtils from "../catalog/catalog.utils"
import { Characteristics } from "../catalog/catalog.constants"
import { visualizationQueryFn } from "./visualization.api.utils"
import { ArResponse } from "./visualization.types"
import { ArDefaultValues } from "./visualization.api.constants"
import { IAppSettings, VisualizationService } from "@encoway/cui-application-components"
import { getEnvironment } from "../environment/environment.utils"
import { Settings } from "../../settings"
import { createRenderingsResult } from "./visualization.utils"

export const VisualizationApi = createApi({
    reducerPath: "visualizationApi",
    baseQuery: fakeBaseQuery<Error>(),
    endpoints: builder => ({
        ar: builder.query<ArResponse, { product: Product | undefined; id: string | undefined; cuiAppSettings: IAppSettings }>({
            queryFn: visualizationQueryFn({
                spinner: () => L10n.format(TranslationKeys.busy.visualization.ar),
                query: async ({ product, id, cuiAppSettings }, visualization, api) => {
                    const environment = await getEnvironment(api)
                    const scene = visualization.cloud.graph().scene()
                    const exporter = await visualization.useExtension("3d-exporter", Settings.visualization.version, environment.visualizationToken)
                    const visualizationUiSettings: any = cuiAppSettings?.visualization?.settings?.ui
                    // @ts-ignore
                    const result = await exporter.exportScene("glb", scene, {
                        compress: true,
                        dracoEncoderPath: visualizationUiSettings.resources.draco.decoderPath
                    })
                    const glb = result.content

                    const response = await fetch(environment.arServiceHost + "/upload", {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                            Accept: "application/json",
                            Authorization: `Bearer ${environment.visualizationToken}`
                        },
                        body: JSON.stringify({
                            id: id,
                            placement: (product && CatalogUtils.getCharacteristicValue(product, Characteristics.ArPlacement.id)) ?? ArDefaultValues.PLACEMENT,
                            scaleFactor:
                                (product && CatalogUtils.getCharacteristicValue<NumberValue>(product, Characteristics.ArScaleFactor.id)?.value) ??
                                ArDefaultValues.SCALE_FACTOR,
                            retentionTime:
                                (product && CatalogUtils.getCharacteristicValue<NumberValue>(product, Characteristics.RetentionTime.id)?.value) ??
                                environment.defaultArRetentionTime,
                            glb: Buffer.from(glb).toString("base64")
                        }),
                        withCredentials: true,
                        credentials: "include"
                    } as RequestInit)

                    if (response.ok) {
                        return await response.json()
                    } else {
                        throw new Error(L10n.format(TranslationKeys.pages.project.configuration.visualization.ar.errorMessage))
                    }
                }
            })
        }),

        renderings: builder.query<Record<string, string> | undefined, IAppSettings>({
            queryFn: visualizationQueryFn({
                spinner: () => L10n.format(TranslationKeys.busy.visualization.image),
                query: async cuiAppSettings => {
                    const determinedRenderSettings = (await VisualizationService.determineRenderSettings(
                        cuiAppSettings.configuration.options.articleName,
                        cuiAppSettings.visualization!.settings!
                    )) as Record<string, any> | undefined
                    if (determinedRenderSettings) {
                        const { orthographicRenderSettings, ...renderSettings } = determinedRenderSettings
                        const renderings = await VisualizationService.render(renderSettings)
                        const orthographicRenderings = orthographicRenderSettings ? await VisualizationService.render(orthographicRenderSettings) : undefined
                        return createRenderingsResult(renderings, orthographicRenderings)
                    }
                }
            })
        })
    })
})
