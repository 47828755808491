import { Characteristics } from "../../../../../../../../features/catalog/catalog.constants"
import { SalesSlice } from "../../../../../../../../features/sales/sales.slice"
import { Dispatch } from "../../../../../../../../store/store.types"

export function setDefaultLayout(layout: string, dispatch: Dispatch) {
    if (layout === Characteristics.CuiDefaultLayout.possibleValues.FocusVisualization) {
        dispatch(SalesSlice.actions.changeConfigurationLayout({ hideConfigurationTree: true, hideConfiguration: false, hideVisualization: false }))
    } else if (layout === Characteristics.CuiDefaultLayout.possibleValues.OnlyConfiguration) {
        dispatch(SalesSlice.actions.changeConfigurationLayout({ hideConfigurationTree: true, hideConfiguration: false, hideVisualization: true }))
    } else if (layout === Characteristics.CuiDefaultLayout.possibleValues.FocusConfiguration) {
        dispatch(SalesSlice.actions.changeConfigurationLayout({ hideConfigurationTree: false, hideConfiguration: false, hideVisualization: true }))
    }
}
