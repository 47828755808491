import { ChangeEventHandler, useState } from "react"
import { Button, Stack } from "@mui/material"
import { L10n } from "@encoway/l10n"
import Dropdown, { DropdownMenuItem } from "../../../../components/dropdown/Dropdown"
import TranslationKeys from "../../../translations/TranslationKeys"
import { DocumentType } from "../../catalog.types"

interface DocumentGenerationFormProps {
    documentTypes: DocumentType[]
    onSubmit: (template: string, format: string) => void
}

export default function DocumentGenerationForm({ documentTypes, onSubmit }: Readonly<DocumentGenerationFormProps>) {
    const [selectedDocumentType, setSelectedDocumentType] = useState<DocumentType | undefined>()
    const [selectedDocumentFormat, setSelectedDocumentFormat] = useState<string>("")

    const updateSelectedDocumentType: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = event => {
        setSelectedDocumentType(JSON.parse(event.target.value))
        if (!event.target.value.includes(selectedDocumentFormat.toLowerCase())) {
            setSelectedDocumentFormat("")
        }
    }
    const updateSelectedDocumentFormat: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = event => setSelectedDocumentFormat(event.target.value)
    const submit = () => onSubmit(selectedDocumentType!.template, selectedDocumentFormat)

    const documentTypeMenuItems: DropdownMenuItem[] = documentTypes.map(documentType => ({
        value: JSON.stringify(documentType),
        text: documentType.name
    }))

    const documentFormatMenuItems: DropdownMenuItem[] =
        selectedDocumentType?.formats.map(format => ({
            value: format.toUpperCase(),
            text: format
        })) ?? []

    return (
        <Stack spacing={2} width={240}>
            <Dropdown
                label={L10n.format(TranslationKeys.components.documentGenerationForm.typeInputLabel)}
                value={selectedDocumentType ? JSON.stringify(selectedDocumentType) : ""}
                onChange={updateSelectedDocumentType}
                menuItems={documentTypeMenuItems}
                size="small"
                cypressId="DocumentGenerationForm.typeInputLabel"
                fullWidth
            />
            <Dropdown
                label={L10n.format(TranslationKeys.components.documentGenerationForm.formatInputLabel)}
                value={selectedDocumentFormat}
                disabled={!selectedDocumentType}
                menuItems={documentFormatMenuItems}
                onChange={updateSelectedDocumentFormat}
                size="small"
                cypressId="DocumentGenerationForm.formatInputLabel"
                fullWidth
            />
            <Button variant="contained" onClick={submit} disabled={selectedDocumentFormat === ""}>
                {L10n.format(TranslationKeys.components.documentGenerationForm.submitButtonLabel)}
            </Button>
        </Stack>
    )
}
